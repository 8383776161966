.cookie-alert {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    text-align: center; /* Add this line */
  }
  
  .cookie-alert p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .cookie-alert .MuiDialogActions-root {
    display: flex;
    justify-content: center;
  }
  
  .cookie-alert button {
    background-color: var(--brown);
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .cookie-alert button:hover {
    background-color: var(--light_brown);
  }
  