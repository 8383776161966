@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

/*--------------------------------------------------------------
# Hero Offer
--------------------------------------------------------------*/
#offer {
  width: 100%;
}

#offer .container {
  padding-top: 72px;
}

#offer h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  font-family: "Dancing Script", cursive;
  stroke-width: 15;
  stroke-dasharray: 1454;
  stroke-dashoffset: 1454;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #e21b1b;
}

#offer h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    color: #4e342e; /* ciemny brąz */
    background: linear-gradient(90deg, rgba(121,85,72,1) 0%, rgba(244,238,224,1) 100%);
    padding: 8px 15px;
    border-radius: 3px;
    text-align: left;
    margin-bottom: 25px;
    font-family: "Garamond", serif;
  }
  

#offer h4 {
  font-family: "Garamond", serif;
  font-size: 22px;
}

#offer p {
  font-size: 15px;
  font-family: "Josefin Sans";
}

.new {
  font-style: italic;
  color: #f77fe7; /* Możesz dostosować kolor, np. na czerwony, aby przyciągnąć uwagę */
}

.highlight {
  font-weight: bold;
  color: #000000; /* Możesz dostosować kolor podświetlenia */
  font-size: 12px;
}
