#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
}

#header.header-scrolled,
#header.header-inner-pages {
  background: rgba(255, 255, 255, 0.8);
}

#header .logo {
  font-size: 30px;
  font-family: "Dancing Script", cursive;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
}

#header .logo a {
  color: var(--brown);
}

#header .logo img {
  max-height: 40px;
}

.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
  margin-right: 10px; /* Reduce space between buttons */
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 20px; /* Adjust padding to be smaller */
  font-size: 14px; /* Reduce font size */
  font-weight: 500;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: var(--gold);
}

.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 6px 15px; /* Make the button smaller */
  margin-left: 15px;
  border-radius: 50px;
  color: #72471f;
  font-size: 13px; /* Reduce font size */
  border: 2px solid #47b2e4;
  font-weight: 600;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #311231;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #0c3c53;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #47b2e4;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

.mobile-nav-toggle {
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(113, 87, 69, 0.6);
    transition: 0.3s;
    z-index: 999;
  }

  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .navbar-mobile ul {
    display: block;
    position: relative;
    top: 55px;
    right: 15px;
    bottom: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
    text-align: center;
  }

  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
  }

  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover > a {
    color: var(--beige);
  }

  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
    color: #37517e;
  }

  .navbar-mobile .dropdown ul {
    position: static;
    display: block;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }

  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }

  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }

  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }

  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover > a {
    color: #47b2e4;
  }

  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
}

/* Existing button styles */
.button-brown-contained {
  background-color: rgba(83, 43, 43, 0.815);
  color: white;
  transition: background-color 0.3s;
}

.button-brown-contained:hover {
  background-color: #5c4033;
}

.button-brown-outlined {
  border-color: brown;
  color: brown;
  transition: border-color 0.3s, color 0.3s;
}

.button-brown-outlined:hover {
  border-color: #5c4033;
  color: #5c4033;
}

/* New styles for mobile */
@media (max-width: 500px) {
  .mobile-buttons {
    display: flex;
    align-items: center;
  }

  .icon-button {
    color: brown;
    margin-left: 5px;
  }

  .icon-button .MuiSvgIcon-root {
    font-size: 1.2rem;
  }

  .button-brown-contained,
  .button-brown-outlined {
    font-size: 0.6rem; /* Further reduced font-size for mobile */
    padding: 2px 6px; /* Further reduced padding for mobile */
  }
}


.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  box-shadow: 24px;
  padding: 16px;
}

.modal-button {
  margin-top: 16px;
  background-color: brown !important;
  color: white !important;
}

.modal-button-outlined {
  margin-top: 16px;
  color: brown !important;
  border-color: brown !important;
}

.modal-button-text {
  margin-top: 16px;
}
