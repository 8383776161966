.admin .content {
    padding: 60px 100px 0 100px;
  }
  
  .admin .content p {
    font-size: 15px;
  }
  
  .admin .accordion-list {
    padding: 0 100px 60px 100px;
  }
  
  .admin .accordion-list ul {
    padding: 0;
    list-style: none;
  }
  
  .admin .accordion-list li + li {
    margin-top: 15px;
  }
  
  .admin .accordion-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
  }
  
  .admin .accordion-list a {
    display: inline-block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 30px;
    outline: none;
    cursor: pointer;
    text-align: center;
    padding: 20px;
  }
  
  .admin .accordion-list span {
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
  }
  
  .lessonatteadminndies .accordion-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .admin .accordion-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  .admin .accordion-list .icon-show {
    display: none;
  }
  
  .admin .accordion-list a.collapsed {
    color: var(--brown);
  }
  
  .admin .accordion-list a.collapsed:hover {
    color: var(--brown);
  }
  
  .admin .accordion-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .admin .accordion-list a.collapsed .icon-close {
    display: none;
  }
  
  @media (max-width: 1024px) {
    .admin .content,
    .admin .accordion-list {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  @media (max-width: 992px) {
    .admin .content {
      padding-top: 30px;
    }
  
    .admin .accordion-list {
      padding-bottom: 30px;
    }
  }
  