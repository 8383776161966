#footer {
    font-size: 14px;
    background: var(--beige);
    font-family: "Josefin Sans";
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact h3 {
    font-size: 28px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 600;
    font-family: "Dancing Script", cursive;
  }
  
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    color: #5e5e5e;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #47b2e4;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #47b2e4;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: var(--beige);
    color: rgb(182, 98, 33);
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: rgb(182, 98, 33);
    color: #fff;
    text-decoration: none;
  }
  
  #footer .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff;
  }
  
  #footer .copyright {
    float: left;
    color: var(--brown);
  }
  
  #footer .credits {
    float: right;
    font-size: 13px;
  }
  
  #footer .credits a {
    transition: 0.3s;
  }
  
  @media (max-width: 768px) {
    #footer .footer-bottom {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  
    #footer .copyright,
    #footer .credits {
      text-align: center;
      float: none;
    }
  
    #footer .credits {
      padding-top: 4px;
    }
  }
  
  /*--------------------------------------------------------------
  # Hero Offer
  --------------------------------------------------------------*/
  #offer {
    width: 100%;
  }
  
  #offer .container {
    padding-top: 72px;
  }
  
  #offer h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    font-family: "Dancing Script", cursive;
    stroke-width: 15;
    stroke-dasharray: 1454;
    stroke-dashoffset: 1454;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #e21b1b;
  }
  
  #offer h3 {
    margin: 0 0 10px 0;
    font-size: 34px;
    font-weight: 700;
    line-height: 56px;
    font-family: "Josefin Sans";
    stroke-width: 15;
    stroke-dasharray: 1454;
    stroke-dashoffset: 1454;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #e21b1b;
    font-family: "Dancing Script", cursive;
  }
  
  #offer p {
    font-size: 15px;
    font-family: "Josefin Sans";
  }
