#timetable {
    margin-top: 20px;
  }
  
  #timetable .white-table {
    background: #fff;
  }
  
  #timetable .bg-light-gray {
    background-color: #f7f7f7;
  }
  #timetable .table-bordered thead td,
  .table-bordered thead th {
    border-bottom-width: 2px;
  }
  #timetable .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  #timetable .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6;
  }
  
  #timetable .bg-sky.box-shadow {
    box-shadow: 0px 5px 0px 0px #00a2a7;
  }
  
  #timetable .bg-orange.box-shadow {
    box-shadow: 0px 5px 0px 0px #af4305;
  }
  
  #timetable .bg-green.box-shadow {
    box-shadow: 0px 5px 0px 0px #4ca520;
  }
  
  #timetable .bg-yellow.box-shadow {
    box-shadow: 0px 5px 0px 0px #dcbf02;
  }
  
  #timetable .bg-pink.box-shadow {
    box-shadow: 0px 5px 0px 0px #e82d8b;
  }
  
  #timetable .bg-purple.box-shadow {
    box-shadow: 0px 5px 0px 0px #8343e8;
  }
  
  #timetable .bg-lightred.box-shadow {
    box-shadow: 0px 5px 0px 0px #d84213;
  }
  
  #timetable .bg-brown.box-shadow {
    box-shadow: 0px 5px 0px 0px #d84213;
  }
  
  #timetable .bg-sky {
    background-color: #02c2c7;
  }
  
  #timetable .bg-orange {
    background-color: #e95601;
  }
  
  #timetable .bg-green {
    background-color: #5bbd2a;
  }
  
  #timetable .bg-yellow {
    background-color: #f0d001;
  }
  
  #timetable .bg-pink {
    background-color: #ff48a4;
  }
  
  #timetable .bg-purple {
    background-color: #9d60ff;
  }
  
  #timetable .bg-lightred {
    background-color: #ff5722;
  }
  
  #timetable .bg-gold {
    background-color: #a37437;
  }
  
  #timetable .padding-15px-lr {
    padding-left: 15px;
    padding-right: 15px;
  }
  #timetable .padding-5px-tb {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  #timetable .margin-10px-bottom {
    margin-bottom: 10px;
  }
  #timetable .border-radius-5 {
    border-radius: 5px;
  }
  
  #timetable .margin-10px-top {
    margin-top: 10px;
  }
  #timetable .font-size14 {
    font-size: 14px;
  }
  
  #timetable .text-light-gray {
    color: #d6d5d5;
  }
  #timetable .font-size13 {
    font-size: 13px;
  }
  
  #timetable .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6;
  }
  #timetable .table td,
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
  }
  
  #timetable span {
    cursor: pointer;
  }
  
  #timetable .bg-brown {
    background-color: var(--brown);
  }
  
  #timetable .week_chooser {
    text-align: center;
    width: 550px;
    margin: auto;
    border-top: solid 1px var(--color-calendar-separator-border);
    padding: 10px 0;
  }
  
  #timetable .week_chooser .left {
    float: left;
  }
  
  #timetable .week_chooser .left,
  .week_chooser .right {
    line-height: 1em;
    top: 0;
    font-size: 30px;
  }
  
  #timetable .week_chooser .right {
    display: block;
    position: relative;
    transition: color 0.3s ease-in;
    float: right;
    right: 40px;
    line-height: 1em;
    top: 0;
    font-size: 30px;
  }
  
.lesson-group-cell {
  vertical-align: top;
  padding: 10px;
}

.lesson-block {
  margin-bottom: 10px;
}
