.lesson-type {
  width: 20%;
}

.trainer-name {
  width: 15%;
}

.day {
  width: 15%;
}

.date {
  width: 20%;
}

.time {
  width: 15%;
}

.actions {
  width: 15%; /* Adjusted to fit two action columns within the row */
  text-align: center;
}

.actions .MuiButton-root {
  font-size: 0.8rem;
  padding: 5px 10px;
}
