.statute-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 800px;
}

.statute-card {
  width: 100%;
}

.statute-item {
  margin-bottom: 40px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s;
}

.statute-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.statute-item h2 {
  margin-bottom: 10px;
  color: #ff6600;
}

.statute-section {
  margin-bottom: 20px;
}

.statute-section h3 {
  margin-bottom: 10px;
  color: #333;
}

.statute-section p {
  margin: 0;
  line-height: 1.6;
  color: #666;
}

.statute-item a {
  display: inline-block;
  margin-top: 10px;
  color: #ff6600;
  text-decoration: none;
  font-weight: bold;
}

.statute-item a:hover {
  text-decoration: underline;
}

.statute-item .MuiTypography-body2 {
  cursor: pointer;
  margin-right: 8px;
}
