/* LoginModal.css */
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  box-shadow: 24px;
  padding: 16px;
}

.modal-button {
  margin-top: 16px;
  background-color: #5c4033;
  color: white;
}

.modal-button-outlined {
  margin-top: 16px;
  color: #5c4033;
  border-color: #5c4033;
}

.modal-button-text {
    margin-top: 16px;
    color: #5c4033;
}

.custom-textfield .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #5c4033; /* Change this to your desired color */
    }
  }
