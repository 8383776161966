/**
* Template Name: Arsha - v4.10.0
* Template URL: https://bootstrapmade.com/arsha-free-bootstrap-html-template-corporate/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Jost:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");

:root {
  --beige: #ede8d8;
  --light_brown: rgb(124, 90, 67);
  --brown: rgb(70, 54, 43);
  --gold: #d8a746;
  --dark_beige: #867f67;
}

body {
  font-family: "Josefin Sans";
  color: var(--brown);
  background: var(--beige);
}

a {
  color: var(--brown);
  text-decoration: none;
}

a:hover {
  color: var(--beige);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Jost", sans-serif;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: var(--beige);
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--beige);
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: var(--brown);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: var(--beige);
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
}

#header.header-scrolled,
#header.header-inner-pages {
  background: rgba(255, 255, 255, 0.8);
}

#header .logo {
  font-size: 30px;
  font-family: "Dancing Script", cursive;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
}

#header .logo a {
  color: var(--brown);
}

#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: var(--gold);
}

.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #72471f;
  font-size: 14px;
  border: 2px solid #47b2e4;
  font-weight: 600;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #311231;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #0c3c53;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #47b2e4;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  /* .navbar ul {
    display: none;
  } */
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(113, 87, 69, 0.6);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: relative;
  top: 55px;
  right: 15px;
  bottom: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
  text-align: center;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: var(--beige);
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
  color: #37517e;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: block;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #47b2e4;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 80vh;
}

#hero .container {
  padding-top: 72px;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  font-family: "Dancing Script", cursive;
  stroke-width: 15;
  stroke-dasharray: 1454;
  stroke-dashoffset: 1454;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #e21b1b;
}

#hero h2 {
  margin-bottom: 50px;
  font-size: 20px;
  font-family: "Josefin Sans", cursive;
}

#hero .btn-get-started {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: #47b2e4;
}

#hero .btn-get-started:hover {
  background: #209dd8;
}

#hero .btn-watch-video {
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  margin: 10px 0 0 25px;
  color: #fff;
  line-height: 1;
}

#hero .btn-watch-video i {
  line-height: 0;
  color: #fff;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

#hero .btn-watch-video:hover i {
  color: #47b2e4;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

#hero .hero-circulated {
  border-radius: 50%;
  width: 80%;
  height: auto;
}

@media (max-width: 991px) {
  #hero {
    height: 100vh;
    text-align: center;
  }

  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }

  #hero .btn-get-started {
    font-size: 16px;
    padding: 10px 24px 11px 24px;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: var(--beige);
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: var(--brown);
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# About us
--------------------------------------------------------------*/
.about-us .content {
  padding: 60px 100px 0 100px;
}

.about-us .content h3 {
  font-weight: 400;
  font-size: 34px;
  font-family: "Dancing Script", cursive;
}

.about-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.about-us .content p {
  font-size: 15px;
  font-family: "Josefin Sans";
}

.about-us .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 65%;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.about-us .accordion-list {
  padding: 0 100px 60px 100px;
}

.about-us .accordion-list ul {
  padding: 0;
  list-style: none;
}

.about-us .accordion-list li + li {
  margin-top: 15px;
}

.about-us .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.about-us .accordion-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
}

.about-us .accordion-list span {
  color: #47b2e4;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.about-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.about-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.about-us .accordion-list .icon-show {
  display: none;
}

.about-us .accordion-list a.collapsed {
  color: #343a40;
}

.about-us .accordion-list a.collapsed:hover {
  color: #47b2e4;
}

.about-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.about-us .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {
  .about-us .content,
  .about-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .about-us .img {
    min-height: 200px;
  }

  .about-us .content {
    padding-top: 30px;
  }

  .about-us .accordion-list {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .about-us .img {
    min-height: 200px;
  }
}

/*--------------------------------------------------------------
# Single pricing elem
--------------------------------------------------------------*/
.pricing-elem .row {
  padding-top: 40px;
}

.pricing-elem .box {
  padding: 60px 40px;
  box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
  background: #fff;
  height: 100%;
  border-top: 4px solid #fff;
  border-radius: 5px;
}

.pricing-elem h3 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
}

.pricing-elem h4 {
  font-size: 48px;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  margin-bottom: 25px;
  text-align: center;
}

.pricing-elem p {
  font-size: 30px;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  margin-bottom: 25px;
  text-align: center;
  font-weight: bold;
}

.pricing-elem h4 sup {
  font-size: 28px;
}

.pricing-elem h4 span {
  font-size: 18px;
  display: block;
}

.pricing-elem ul {
  padding: 20px 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing-elem ul li {
  padding: 10px 0 10px 30px;
  position: relative;
}

.pricing-elem ul i {
  font-size: 24px;
  position: absolute;
  left: 0;
  top: 6px;
}

.pricing-elem ul .na {
  color: var(--brown);
}

.pricing-elem ul .na i {
  color: var(--brown);
}

.pricing-elem ul .na span {
  text-decoration: line-through;
}

.pricing-elem .buy-btn {
  display: inline-block;
  padding: 12px 35px;
  border-radius: 50px;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid var(--beige);
}

.pricing-elem .buy-btn:hover {
  background: var(--beige);
  color: #fff;
}

.pricing-elem .featured {
  border-top-color: var(--beige);
}

.pricing-elem .featured .buy-btn {
  background: var(--beige);
  color: #fff;
}

.pricing-elem .featured .buy-btn:hover {
  background: rgb(182, 98, 33);
}

@media (max-width: 992px) {
  .pricing-elem .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing-elem .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing-elem .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .content {
  padding: 60px 100px 0 100px;
}

.pricing .content p {
  font-size: 15px;
}

.pricing .accordion-list {
  padding: 0 100px 60px 100px;
}

.pricing .accordion-list ul {
  padding: 0;
  list-style: none;
}

.pricing .accordion-list li + li {
  margin-top: 15px;
}

.pricing .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.pricing .accordion-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
  text-align: center;
  padding: 20px;
}

.pricing .accordion-list span {
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.pricing .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.pricing .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.pricing .accordion-list .icon-show {
  display: none;
}

.pricing .accordion-list a.collapsed {
  color: var(--brown);
}

.pricing .accordion-list a.collapsed:hover {
  color: var(--brown);
}

.pricing .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.pricing .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {
  .pricing .content,
  .pricing .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .pricing .content {
    padding-top: 30px;
  }

  .pricing .accordion-list {
    padding-bottom: 30px;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  border-top: 3px solid;
  border-bottom: 3px solid;
  padding: 30px;
  background: var(--beige);
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.contact .info i {
  font-size: 20px;
  color: rgb(182, 98, 33);
  float: left;
  width: 44px;
  height: 44px;
  background: #e7f5fb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #261601;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #261601;
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: #47b2e4;
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: rgb(182, 98, 33);
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  border-top: 3px solid #47b2e4;
  border-bottom: 3px solid #47b2e4;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}

.contact .php-email-form label {
  padding-bottom: 8px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #47b2e4;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #47b2e4;
  border: 0;
  padding: 12px 34px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #209dd8;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f3f5fa;
  min-height: 40px;
  margin-top: 72px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 68px;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 600;
  color: #37517e;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4668a2;
  content: "/";
}

/*--------------------------------------------------------------
# Timetable
--------------------------------------------------------------*/

#timetable {
  margin-top: 20px;
}

#timetable .white-table {
  background: #fff;
}

#timetable .bg-light-gray {
  background-color: #f7f7f7;
}
#timetable .table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
#timetable .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
#timetable .table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

#timetable .bg-sky.box-shadow {
  box-shadow: 0px 5px 0px 0px #00a2a7;
}

#timetable .bg-orange.box-shadow {
  box-shadow: 0px 5px 0px 0px #af4305;
}

#timetable .bg-green.box-shadow {
  box-shadow: 0px 5px 0px 0px #4ca520;
}

#timetable .bg-yellow.box-shadow {
  box-shadow: 0px 5px 0px 0px #dcbf02;
}

#timetable .bg-pink.box-shadow {
  box-shadow: 0px 5px 0px 0px #e82d8b;
}

#timetable .bg-purple.box-shadow {
  box-shadow: 0px 5px 0px 0px #8343e8;
}

#timetable .bg-lightred.box-shadow {
  box-shadow: 0px 5px 0px 0px #d84213;
}

#timetable .bg-brown.box-shadow {
  box-shadow: 0px 5px 0px 0px #d84213;
}

#timetable .bg-sky {
  background-color: #02c2c7;
}

#timetable .bg-orange {
  background-color: #e95601;
}

#timetable .bg-green {
  background-color: #5bbd2a;
}

#timetable .bg-yellow {
  background-color: #f0d001;
}

#timetable .bg-pink {
  background-color: #ff48a4;
}

#timetable .bg-purple {
  background-color: #9d60ff;
}

#timetable .bg-lightred {
  background-color: #ff5722;
}

#timetable .bg-gold {
  background-color: #a37437;
}

#timetable .padding-15px-lr {
  padding-left: 15px;
  padding-right: 15px;
}
#timetable .padding-5px-tb {
  padding-top: 5px;
  padding-bottom: 5px;
}

#timetable .margin-10px-bottom {
  margin-bottom: 10px;
}
#timetable .border-radius-5 {
  border-radius: 5px;
}

#timetable .margin-10px-top {
  margin-top: 10px;
}
#timetable .font-size14 {
  font-size: 14px;
}

#timetable .text-light-gray {
  color: #d6d5d5;
}
#timetable .font-size13 {
  font-size: 13px;
}

#timetable .table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
#timetable .table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  white-space: nowrap;
}

#timetable span {
  cursor: pointer;
}

#timetable .bg-brown {
  background-color: var(--brown);
}

#timetable .week_chooser {
  text-align: center;
  width: 550px;
  margin: auto;
  border-top: solid 1px var(--color-calendar-separator-border);
  padding: 10px 0;
}

#timetable .week_chooser .left {
  float: left;
}

#timetable .week_chooser .left,
.week_chooser .right {
  line-height: 1em;
  top: 0;
  font-size: 30px;
}

#timetable .week_chooser .right {
  display: block;
  position: relative;
  transition: color 0.3s ease-in;
  float: right;
  right: 40px;
  line-height: 1em;
  top: 0;
  font-size: 30px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  font-size: 14px;
  background: var(--beige);
  font-family: "Josefin Sans";
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #fff;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 28px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 600;
  font-family: "Dancing Script", cursive;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  color: #5e5e5e;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #47b2e4;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #47b2e4;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: var(--beige);
  color: rgb(182, 98, 33);
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: rgb(182, 98, 33);
  color: #fff;
  text-decoration: none;
}

#footer .footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
}

#footer .copyright {
  float: left;
  color: var(--brown);
}

#footer .credits {
  float: right;
  font-size: 13px;
}

#footer .credits a {
  transition: 0.3s;
}

@media (max-width: 768px) {
  #footer .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #footer .copyright,
  #footer .credits {
    text-align: center;
    float: none;
  }

  #footer .credits {
    padding-top: 4px;
  }
}


/*--------------------------------------------------------------
# Admin
--------------------------------------------------------------*/
.admin .content {
  padding: 60px 100px 0 100px;
}

.admin .content p {
  font-size: 15px;
}

.admin .accordion-list {
  padding: 0 100px 60px 100px;
}

.admin .accordion-list ul {
  padding: 0;
  list-style: none;
}

.admin .accordion-list li + li {
  margin-top: 15px;
}

.admin .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.admin .accordion-list a {
  display: inline-block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
  text-align: center;
  padding: 20px;
}

.admin .accordion-list span {
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.lessonatteadminndies .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.admin .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.admin .accordion-list .icon-show {
  display: none;
}

.admin .accordion-list a.collapsed {
  color: var(--brown);
}

.admin .accordion-list a.collapsed:hover {
  color: var(--brown);
}

.admin .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.admin .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {
  .admin .content,
  .admin .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .admin .content {
    padding-top: 30px;
  }

  .admin .accordion-list {
    padding-bottom: 30px;
  }
}

/*--------------------------------------------------------------
# Cookie alert
--------------------------------------------------------------*/
.cookie-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  text-align: center; /* Add this line */
}

.cookie-alert p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

.cookie-alert .MuiDialogActions-root {
  display: flex;
  justify-content: center;
}

.cookie-alert button {
  background-color: var(--brown);
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.cookie-alert button:hover {
  background-color: var(--light_brown);
}
